/* react-select custom css */

.css-1pahdxg-control:hover {
  border-color: #d4d6d9 !important;
}

.css-1wa3eu0-placeholder {
  color: #a3b1bf !important;
}
:focus {
  outline: unset !important;
}

.css-1okebmr-indicatorSeparator {
  display: none !important;
}

.select__menu-list {
  max-height: 170px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  text-align: left;
}

.select__option--is-focused {
  background-color: #fbf8f8 !important;
  color: #453561 !important
}

.select__option--is-selected {
  background-color: #453561 !important;
  color: #ffffff !important;
}

/*.css-9gakcf-option {
  background-color: #453561 !important;
}*/

.css-2b097c-container {
  background: #ffffff;
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.17);
  border-radius: 6px;
  box-shadow: none !important;
}

.react-select {
  box-shadow: none !important;
}

/* .react-select .css-g1d714-ValueContainer {
  min-height: 50px !important;
} */

.react-select .css-g1d714-ValueContainer {
  min-height: 50px !important;
}

.react-select .css-1pahdxg-control {
  border-color: #d4d6d9 !important;
  box-shadow: none !important;
  min-height: 50px !important;
}

.react-select .css-yk16xz-control {
  border: 1px solid #d4d6d9 !important;
  box-sizing: border-box !important;
  border-radius: 6px !important;
  height: 50px !important;
}

.city-select-custom-style .css-yk16xz-control .css-g1d714-ValueContainer {
  min-height: 40px !important;
}

.city-select-custom-style .css-1pahdxg-control:hover {
  border-color: #d4d6d9 !important;
  min-height: 40px !important;
}

.city-select-custom-style .css-1pahdxg-control {
  border-color: #d4d6d9 !important;
  box-shadow: none !important;
  height: 40px !important;
}

.city-select-custom-style .css-yk16xz-control {
  border: 1px solid #d4d6d9 !important;
  box-sizing: border-box !important;
  border-radius: 6px !important;
  height: 40px !important;
}

.celebrity-react-select .css-1wa3eu0-placeholder {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  color: #cacfda !important;
}

.search-react-custom .css-1wa3eu0-placeholder {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  color: #a3b1bf !important;
}

.celebrity-react-select .css-yk16xz-control {
  min-height: 50px !important;
  background: #ffffff;
  border: 1px solid #cacfda !important;
  box-sizing: border-box !important;
}

.celebrity-react-select .css-yk16xz-control .css-g1d714-ValueContainer {
  min-height: 50px !important;
}

.celebrity-react-select .css-yk16xz-control {
  height: 50px !important;
}

/* css for horizontal line */
hr {
  border-top: 1px solid #dae2f5 !important;
  margin-top: 0.7rem !important;
}
/* datepicker custom css */

.react-datepicker__input-container {
  display: block !important;
}

.react-datepicker-wrapper {
  display: block !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected {
  background-color: #453561 !important;
}

.react-datepicker__header {
  background-color: #453561 !important;
}

/* input type number css */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* toster notification override css */

.Toastify__toast {
  font-family: "Poppins", sans-serif !important;
  padding: 8px 12px !important;
  min-height: 60px !important;
  border-radius: 6px !important;
}

.Toastify__toast-container {
  width: 400px !important;
}

.Toastify__toast-body {
  text-align: left;
}

.Toastify__toast--success {
  background: #007a5a !important;
}

.form-control:disabled {
  background-color: transparent !important;
}

/* carousal custom css */

.control-dots {
  padding-left: 0px !important;
}
.search-service-carousal .carousel .slide img {
  height: 530px;
  object-fit: cover;
}

.testimonial-carousal .carousel .slide img {
  height: 85px;
  width: 85px;
  border-radius: 50%;
}

.testimonial-carousal .carousel .slider-wrapper {
  height: 370px;
}

.search-service-carousal .carousel .slider-wrapper {
  height: 530px;
}

.testimonial-carousal .carousel.carousel-slider {
  background: #ffffff;
}

.testimonial-carousal .carousel .slide {
  background: #ffffff !important;
}

/*.carousel .control-dots {
  margin-top: 50px;
  margin-bottom: 15px;
}*/

.carousel .control-dots .dot.selected {
  background: #453561 !important;
}

/* react select css override */

/* .select-vendor-service-class .css-2b097c-container .css-g1d714-ValueContainer {
  min-height: 48px !important;
}
.select-vendor-city-class .css-2b097c-container .css-g1d714-ValueContainer {
  min-height: 48px !important;
} */

@media only screen and (max-device-width: 550px) {
  .search-service-carousal .carousel .slide img {
    height: 490px;
    object-fit: cover;
  }

  .search-service-carousal .carousel .slider-wrapper {
    height: 490px;
  }

  .testimonial-carousal .carousel .slide img {
    height: 85px;
    width: 85px;
    border-radius: 50%;
  }

  .testimonial-carousal .carousel .slider-wrapper {
    height: 590px;
  }

  .search-form-container {
    margin-top: 10px !important;
  }

  .carousal-search-container {
    margin-top: 20px !important;
    position: absolute;
    right: 0;
    left: 0;
    z-index: 998;
  }
  .select-vendor-city-class {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .select__menu-list {
    max-height: 150px !important;
  }

  .Toastify__toast-container {
    width: unset !important;
    margin-left: 15px !important;
  }
}

@media only screen and (max-device-width: 400px) {
  .testimonial-carousal .carousel .slider-wrapper {
    height: 670px;
  }
}

.carousal-search-container {
  margin-top: 110px;
  position: absolute;
  right: 0;
  left: 0;
  z-index: 998;
}
.carousal-search-heading {
  text-align: center;
  font-weight: 500;
  font-size: 38px;
  line-height: 46px;
  color: #ffffff;
  text-shadow: 0px 4px 15px rgba(31, 22, 45, 0.3);
}

.carousal-search-text {
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  align-items: center;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 4px 15px rgba(31, 22, 45, 0.17);
  margin-top: 12px;
}

.search-form-container {
  width: 95%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
}

/* popup Modal override css */

.modal-content {
  overflow: hidden;
}

/* checkbox css */

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */

.checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 18px;
  width: 18px;
  border-radius: 3px;
  background-color: #ffffff;
  border: 2px solid #46405c;
}

.cart-checkmark {
  height: 18px;
  width: 18px;
  border-radius: 3px;
  background-color: #ffffff;
  border: 2px solid #46405c;
}

/* .checkbox-container:hover input ~ .checkmark {
  opacity: 0.5;
} */

/* When the checkbox is checked, add a blue background */

.checkbox-container input:checked ~ .checkmark {
  background-color: #453561;
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-container .checkmark:after {
  left: 3px;
  top: 0px;
  width: 6px;
  height: 10px;
  border: solid #1f1a33;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* toaster top-center css override */

.cart-modal-container {
  margin-top: 12%;
}

.Toastify__toast-container--top-center {
  margin-top: 7%;
}

.add-to-cart-toaster {
  background: #ffffff !important;
  max-width: 310px !important;
  padding: 15px 15px !important;
  max-height: 50px !important;
}

.add-to-cart-toaster .Toastify__close-button {
  display: none !important;
}

.added-cart-text {
  font-size: 14px;
  line-height: 24px;
  color: #11182e;
}
