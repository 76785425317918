body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  overflow-x: hidden;
}

img {
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -o-user-select: none !important;
  user-select: none !important;
}

input {
  font-size: 14px !important;
  line-height: 24px !important;
  color: #1f162d !important;
}

::-webkit-input-placeholder {
  font-family: "Poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: normal;
  font-size: 16px !important;
  align-items: center !important;
  /*color: #cacfda !important;*/
  color: #8F99B0 !important;
}

::-moz-placeholder {
  font-family: "Poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: normal;
  font-size: 16px !important;
  align-items: center !important;
  /*color: #cacfda !important;*/
  color: #8F99B0 !important;
}

select:hover,
select:focus,
select:active,
textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active {
  box-shadow: none !important;
  outline: none !important;
}

.inline-flex {
  display: inline-flex;
}

.form-group {
  margin-bottom: 0px !important;
}

.form-control.is-invalid {
  border-color: #eb5757 !important;
}

#invalid-input-border {
  border-color: #eb5757 !important;
}

.form-control:focus {
  border-color: #dae2f5 !important;
}

.btn.disabled {
  opacity: 0.3 !important;
}

.find-button-style.disabled {
  opacity: 0.65 !important;
}

.form-heading-style {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}

.global-label-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #ffffff;
  margin-bottom: 1px !important;
  display: block;
}
.underline:hover {
  text-decoration: underline;
}
.global-text-style {
  font-size: 14px;
  line-height: 27px;
  color: #8f99b0;
  display: inline-block;
}
.component-heading-text {
  font-weight: 500;
  font-size: 24px;
  line-height: 46px;
  color: #11182e;
}
.minHeight500 {
  min-height: 500px;
}

.text-ellipse {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.cursor {
  cursor: pointer !important;
}

.mt30 {
  margin-top: 30px;
}

.mt20 {
  margin-top: 20px !important;
}

.mt24 {
  margin-top: 24px;
}

.mt10 {
  margin-top: 10px;
}

.mt6 {
  margin-top: 6px;
}
.mt4 {
  margin-top: 4px;
}
.mt40 {
  margin-top: 40px;
}

.mb15 {
  margin-bottom: 15px !important;
}

.mb40 {
  margin-bottom: 40px !important;
}

.mb34 {
  margin-bottom: 34px !important;
}

.mt15 {
  margin-top: 15px;
}

.mt25 {
  margin-top: 25px;
}

.mt5 {
  margin-top: 5px;
}

.mb5 {
  margin-bottom: 5px;
}

.mr8 {
  margin-right: 8px;
}

.mr25 {
  margin-right: 25px;
}

.ml25 {
  margin-left: 25px;
}

.ml35 {
  margin-left: 35px;
}

.mf15 {
  margin-left: 15px;
}

.mt50 {
  margin-top: 50px;
}

.mt35 {
  margin-top: 35px;
}

.mf60 {
  margin-left: 60px;
}

.mt18 {
  margin-top: 18px;
}

.mt200 {
  margin-top: 200px;
}

.mt100 {
  margin-top: 100px;
}
.mb100 {
  margin-bottom: 100px;
}

.mt150 {
  margin-top: 150px;
}

.ml12 {
  margin-left: 12px;
}

.mb200 {
  margin-bottom: 200px;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mr30 {
  margin-right: 30px;
}

.pt6 {
  padding-top: 6px;
}

.mb50 {
  margin-bottom: 50px;
}

.pt8 {
  padding-top: 8px;
}

.mrl7 {
  margin-right: 7px;
  margin-left: 7px;
}

.mr0 {
  margin-right: 0px !important;
}

.ml0 {
  margin-left: 0px !important;
}

.p0 {
  padding: 0px !important;
}

.pb8 {
  padding-bottom: 8px;
}

.p20 {
  padding: 20px;
}

.mr5 {
  margin-right: 5px;
}

.mr10 {
  margin-right: 10px;
}

.mr15 {
  margin-right: 15px;
}

.mr20 {
  margin-right: 20px;
}

.mr40 {
  margin-right: 40px;
}

.mr50 {
  margin-right: 50px;
}

#mr50 {
  margin-right: 50px !important;
}

#mr80 {
  margin-right: 80px !important;
}

.mt20vh {
  margin-top: 20vh;
}

.mt7 {
  margin-top: 7px;
}

.mt70 {
  margin-top: 70px;
}

.mt10per {
  margin-top: 10%;
}

.ml50 {
  margin-left: 50px;
}

.mb30 {
  margin-bottom: 30px !important;
}

.mt46 {
  margin-top: 46px;
}

.mb55 {
  margin-bottom: 55px;
}

.mb10 {
  margin-bottom: 10px !important;
}
.mr14 {
  margin-right: 14px;
}

.mt76 {
  margin-top: 76px;
}

.mb76 {
  margin-bottom: 76px;
}

.ml40 {
  margin-left: 40px;
}

.align-center {
  text-align: center;
}

.text-left {
  text-align: left !important;
}

.align-items-center {
  align-items: center;
}

.find-button-style {
  width: 100%;
  height: 48px;
  background: #453561 !important;
  border-radius: 6px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  text-align: center !important;
  letter-spacing: 0.2px !important;
  color: #ffffff !important;
  border-color: #453561 !important;
}
.celebrity-button-style {
  width: 100%;
  height: 50px;
  background: #453561 !important;
  border-radius: 6px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  text-align: center !important;
  letter-spacing: 0.2px !important;
  color: #ffffff !important;
  border-color: #453561 !important;
}

.input-box-style {
  border: 1px solid #d4d6d9 !important;
  box-sizing: border-box !important;
  border-radius: 6px !important;
  height: 50px !important;
}

.field-icon {
  float: right;
  margin-top: -38px;
  margin-right: 10px !important;
  position: relative;
  z-index: 2;
}

.input-error-style {
  font-size: 12px !important;
  line-height: 24px !important;
  color: #eb5757 !important;
  margin-top: 2px;
}

.page-container-box {
  min-height: 100%;
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(136.39deg, #dbd6fd 13.94%, #ffd1df 131.84%);
}

.back-label {
  font-size: 16px;
  line-height: 27px;
  color: #555e7a;
}

.chevron-icon {
  margin-right: 8px;
  height: 24px;
  width: 24px;
}
.label-color {
  color: #46516b;
}
.page-form {
  background: #ffffff;
  box-shadow: 0px 4px 17px rgba(120, 132, 158, 0.08);
  padding: 60px 0px 60px 0px !important;
  /* border: 1px solid black; */
}

.sign-up-logo{
  border-radius: 10px;
  width: 75px;
  height: 75px;
  flex-shrink: 0;
}

.page-form-label {
  font-size: 24px;
  line-height: 36px;
  color: #11182e;
  text-align: center;
  margin-bottom: 50px;
}

.sign-up-logo{
  border-radius: 10px;
  width: 80px;
  height: 80px;
  flex-shrink: 0;
}

.page-content-lable{
  color: var(--Secondary-5, #8F99B0);
  text-align: center;
  
  /* Poppins/M/14 */
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  }

.page-form-button {
  background-color: #453561 !important;
  border-radius: 6px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  text-align: center !important;
  letter-spacing: 0.2px !important;
  color: #ffffff !important;
  border-color: unset !important;
  width: 100% !important;
  height: 50px !important;
  /*display: flex;*/
  align-items: center;
}

.sub-heading-text {
  font-size: 18px;
  line-height: 27px;
  color: #1f162d;
}

.noRecord-heading-text {
  font-size: 18px;
  line-height: 27px;
  color: #1f162d;
  text-align: center;
}
.large-text-heading {
  font-size: 36px;
  line-height: 56px;
  color: #4734b8;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.navigation-container-box {
  display: inline-flex;
}

/* text responsive font sizes and line height changes for mobile responsive */

@media only screen and (max-device-width: 480px) {
  .large-text-heading {
    font-size: 30px !important;
    line-height: 36px !important;
    color: #4734b8;
  }
  .navigation-container-box {
    display: block !important;
  }
  .warning-confirm-button {
    margin-left: 0px !important;
  }
  .warning-modal-body {
    padding: 20px 30px !important;
  }
  .warning-popup-modal {
    margin-top: 40% !important;
  }
  .pagination-col-container {
    text-align: -webkit-center !important;
    text-align: center;
  }
  .policy-box-heading {
    padding-top: 30px !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

/* pop-up modal design css */

.modal-open {
  overflow-y: auto !important;
}

.modal-open .modal {
  overflow: hidden !important;
}

@media (min-width: 576px) {
  .warning-popup-modal {
    max-width: 540px !important;
    margin-top: 15% !important;
  }
  .form-popup-modal {
    max-width: 540px !important;
    margin-top: 10% !important;
  }
  .add-vendor-modal {
    max-width: 540px !important;
    margin-top: 5% !important;
  }
}

.form-modal-body {
  padding: 36px 65px 36px 45px !important;
}

.warning-popup-modal .modal-content {
  box-sizing: border-box;
  border-radius: 4px !important;
  background: #ffffff;
  /* box-shadow: 0px 4px 17px rgba(120, 132, 158, 0.08); */
}

.form-popup-modal .modal-content {
  box-sizing: border-box;
  border-radius: 4px !important;
  background: #ffffff;
  /* box-shadow: 0px 4px 17px rgba(120, 132, 158, 0.08); */
}

.add-vendor-modal .modal-content {
  box-sizing: border-box;
  border-radius: 4px !important;
  background: #ffffff;
  /* box-shadow: 0px 4px 17px rgba(120, 132, 158, 0.08); */
}

.form-modal-heading-text {
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
}

.warning-modal-body {
  padding: 50px 75px !important;
}

.p10 {
  padding: 10px;
}

.warning-login-modal-body {
  padding: 15px 75px 40px 75px !important;
}

.warning-modal-heading-text {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #bdb8cf;
  text-align: center;
}

.warning-modal-detail-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #8f99b0;
}

.pop-next-option-btn {
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.2px;
  color: #453561;
  cursor: pointer;
}

.reject-vendor-btn {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.2px;
  color: #453561;
}

.warning-confirm-button {
  width: 100%;
  background: #453561 !important;
  border-radius: 6px !important;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  text-align: center !important;
  letter-spacing: 0.2px !important;
  color: #ffffff !important;
  border-color: #453561 !important;
  height: 40px !important;
  width: 150px !important;
  margin-top: 35px;
  margin-left: 60px;
}

.warning-modal-cancel-btn {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.2px;
  color: #453561;
  margin-left: 30px;
  margin-top: 35px;
}

.text-center {
  text-align: center;
}

.pt40 {
  padding-top: 40px;
}
.mt12 {
  margin-top: 12px;
}

.service-image-box {
  width: 100% !important;
  height: "auto";
  object-fit: cover;
  cursor: pointer;
}

.service-image-box:hover {
  border: 3px solid #453561;
  border-radius: 15px;
}

.div-min-width {
  min-width: fit-content;
}

.transperent-btn-style {
  width: 100%;
  height: 50px;
  background: #ffffff !important;
  border-radius: 6px !important;
  border: 1px solid #453561 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  text-align: center !important;
  letter-spacing: 0.2px !important;
  color: #453561 !important;
}

.text-right {
  text-align: right;
}
.pagination-count-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #11182e;
  text-align: right;
}

.pagination-number-container {
  display: flex;
  background: #ffffff;
  border: 1px solid #dae2f5;
  box-sizing: border-box;
  border-radius: 3px;
  width: min-content;
}

.next-btn-border-style {
  border-right: none !important;
}
.next-previous-btn-style {
  background: #ffffff;
  border-right: 1px solid #dae2f5;
  box-sizing: border-box;
  font-size: 12px;
  line-height: 18px;
  color: #453561;
  padding: 8px 12px;
  cursor: pointer;
  min-width: 75px;
  text-align: center;
}

.diabled-next-pre-btn {
  background: #ffffff;
  border-right: 1px solid #dae2f5;
  box-sizing: border-box;
  font-size: 12px;
  line-height: 18px;
  color: #453561;
  padding: 8px 12px;
  cursor: pointer;
  min-width: 75px;
  text-align: center;
  cursor: not-allowed;
  opacity: 0.5;
}

.pagination-number-text {
  background: #ffffff;
  border-right: 1px solid #dae2f5;
  box-sizing: border-box;
  padding: 8px 12px;
  font-size: 12px;
  line-height: 18px;
  color: #11182e;
  cursor: pointer;
}
.not-allowed-cursor {
  cursor: not-allowed;
}

.pagination-number-text:hover {
  background: #453561;
  color: #ffffff;
}
.selected-pagination-style {
  background: #453561;
  color: #ffffff;
  border-right: 1px solid #dae2f5;
  box-sizing: border-box;
  padding: 8px 12px;
  font-size: 12px;
  line-height: 18px;
}

#main-pointer-null {
  pointer-events: none;
  opacity: 0.4;
}

@media only screen and (min-width: 1500px) {
  .service-image-box:hover {
    border: 3px solid #453561;
    border-radius: 26px;
  }
}

@media only screen and (max-width: 767px) {
  .pagination-count-text {
    text-align: center;
    margin-bottom: 15px;
  }

  .pagination-number-col {
    display: flex;
    justify-content: center;
  }
  .warning-modal-body {
    padding: 30px 40px !important;
  }
}

.celebrity-input-box-style {
  background: #ffffff;
  border: 1px solid #cacfda;
  box-sizing: border-box;
  border-radius: 6px;
  height: 48px !important;
  width: 100%;
  padding: 6px 12px !important;
}
.celebrity-textarea-box-style {
  background: #ffffff;
  border: 1px solid #cacfda;
  box-sizing: border-box;
  border-radius: 6px;
}

.detail-heading-text {
  color: #11182e;
  font-size: 15px;
  display: inline-block;
}
.basic-detail-tableText {
  font-size: 14px;
  line-height: 27px;
}
.C555E7A {
  color: #555e7a;
}

.information-text {
  color: #555e7a;
}
.font10line24 {
  font-size: 11px;
  line-height: 24px;
  margin-left: 2px;
  color: #8F99B0;
}

.mtper4 {
  margin-top: 4% !important;
}

.warning-login-popup {
  max-width: 420px !important;
  margin-top: 12% !important;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.scroll-top-button {
  opacity: 0.3;
  background-color: #453561;
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 10px;
  right: 10px;
  border-radius: 5px;
  border: none;
}
.scroll-top-button:hover {
  opacity: 1;
}

.arrow-up-scroll-gly {
  height: 30px;
  transform: rotate(180deg);
}

.empty-cart-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #11182e;
  margin-top: 30px;
}

.empty-cart-div {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.privacy-policy-container {
  padding: 10px 30px 30px 30px;
}

/* temporarily added */
.footer-privacy-policy-container {
  padding: 10px 0px 10px 16px;
}

.public-content-color {
  color: #555e7a;
  font-weight: 600;
}

.policy-box-heading {
  text-align: center;
  padding-top: 20px;
}
.pt10 {
  padding-top: 10px;
}

.pt20 {
  padding-top: 20px;
}

.font-bold {
  font-weight: bolder;
}

.main-spinner-position {
  margin-top: 300px;
  text-align: center;
}

.home-container-position-loader {
  margin-top: 100px;
  text-align: center;
}

.c8F99B0 {
  color: #8f99b0;
}
